import RvG from '@/types/rvg'
import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Headline from '@objects/headline'
import Copy from '@components/copy'
import Image from '@objects/image'
import InViewAnimation from '@objects/inViewAnimation'
import FontSize from '@config/theme/definitions/fontSize'
import { useMediaQuery } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  stageRoot: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'row',
      position: 'relative',
      paddingBottom: theme.spacing(0),
    },
    [theme.breakpoints.up('xl')]: {},
  },
  stageImage: {
    margin: '0 !important',
    width: '100%',
    order: 1,
    [theme.breakpoints.up('lg')]: {
      order: 2,
      width: '50%',
      '& .gatsby-image-wrapper': {
        height: '340px',
      },
    },
  },
  contentWrapper: {
    position: 'relative',
    top: 0,
    left: 0,
    backgroundColor: theme.palette.background.greyDark,
    color: theme.palette.text.invert,
    order: 2,
    [theme.breakpoints.up('lg')]: {
      order: 1,
      width: '50%',
      height: 'auto',
      minHeight: '224px',
      transition: `transform 1s ${theme.transitions.easing.easeInOut}`,
    },
  },
  articleDate: {
    color: theme.palette.primary.main,
    fontSize: FontSize.lg,
    fontFamily: theme.typography.fontFamily2,
  },
  contentInner: {
    padding: theme.spacing(8, 4, 16, 4),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      paddingLeft: `calc(100vw / 2 - ${theme.container.lg} / 2)`,
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(10),
    },
  },
}))

export type PressStageProps = RvG.IReactDefaultProps & {
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  image?: RvG.Contentful.IAsset
  imageMobile?: RvG.Contentful.IAsset
  imageFocalPoint?: RvG.Contentful.imageFocalPoint
  articleDate?: string
}

export default function PressStageVariant({
  headline,
  image,
  imageMobile,
  imageFocalPoint,
  copy,
  articleDate,
}: PressStageProps): ReactElement {
  const classes = useStyles()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <section className={classes.stageRoot}>
      {(headline || copy) && (
        <div className={clsx(classes.contentWrapper)}>
          <div className={classes.contentInner}>
            <InViewAnimation
              group={[
                articleDate && (
                  <div className={classes.articleDate}>{articleDate}</div>
                ),
                headline && <Headline>{headline}</Headline>,
                copy && <Copy richtext={copy} />,
              ]}
            />
          </div>
        </div>
      )}
      {image && (
        <Image
          className={classes.stageImage}
          image={image.fluid}
          imageMobile={imageMobile?.fluid}
          focalPoint={imageFocalPoint || 'Upper right'}
          imgStyle={{ margin: '0' }}
          lazyLoadingOff={true}
        />
      )}
    </section>
  )
}
