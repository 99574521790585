import RvG from '@/types/rvg'
import React, { ReactElement } from 'react'
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import 'intersection-observer'
import { useInView } from 'react-intersection-observer'

import Headline from '@objects/headline'
import Copy from '@components/copy'
import Image from '@objects/image'
import { useMediaQuery } from '@material-ui/core'
import FontSize from '@config/theme/definitions/fontSize'

const useStyles = makeStyles((theme) => ({
  stageRoot: {
    marginBottom: theme.spacing(15),
    [theme.breakpoints.up('lg')]: {
      position: 'relative',
      paddingTop: '20%',
      paddingBottom: theme.spacing(0),
    },
    [theme.breakpoints.up('xl')]: {
      paddingTop: '11%',
      paddingBottom: theme.spacing(8),
    },
  },
  stageImage: {
    margin: '0 !important',
    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      '& .gatsby-image-wrapper': {
        height: '340px',
      },
    },
  },
  contentWrapper: {
    position: 'relative',
    backgroundColor: theme.palette.background.greyDark,
    color: theme.palette.text.invert,
    [theme.breakpoints.up('lg')]: {
      width: '50%',
      height: 'auto',
      minHeight: '224px',
      transform: 'translateY(10%)',
      transition: `transform 0.6s ${theme.transitions.easing.easeInOut}`,
    },
  },
  headlineAnim: {
    // this headline should read as an h1 for accessibility but have the font size of an h2:
    fontSize: FontSize['3xl'],
    [theme.breakpoints.up('lg')]: {
      fontSize: FontSize['5xl'],
    },
    marginBottom: theme.spacing(6),
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0s, transform 1s ${theme.transitions.easing.easeInOut} 0s`,
  },
  copyAnim: {
    opacity: 0,
    transform: 'translateY(10%)',
    transition: `opacity 1s ${theme.transitions.easing.easeInOut} 0.2s, transform 1s ${theme.transitions.easing.easeInOut} 0.2s`,
  },
  inView: {
    [theme.breakpoints.up('lg')]: {
      transform: 'translateY(0%)',
    },
    '&$contentWrapper': {
      transform: 'translateY(0%)',
    },
    '& $copyAnim, & $headlineAnim': {
      transform: 'translateY(0%)',
      opacity: 1,
    },
  },
  contentInner: {
    padding: theme.spacing(4),
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing(4),
      paddingLeft: `calc(100vw / 2 - ${theme.container.lg} / 2)`,
      paddingRight: theme.spacing(10),
      paddingTop: theme.spacing(13),
      paddingBottom: theme.spacing(10),
    },
  },
  contentSiding: {
    [theme.breakpoints.up('lg')]: {
      height: '100%',
      width: '50px',
      position: 'absolute',
      top: 0,
      right: '-25px',
      zIndex: 1,
      backgroundColor: theme.palette.background.greyDark,
      transform: 'skew(-6.5deg)',
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
    },
  },
  copy: {
    ...theme.typography.body1,
  },
}))

export type DefaultStageProps = RvG.IReactDefaultProps & {
  headline?: string
  copy?: RvG.Contentful.BasicRichTextType
  image?: RvG.Contentful.IAsset
  imageMobile?: RvG.Contentful.IAsset
  imageFocalPoint?: RvG.Contentful.imageFocalPoint
}

export default function DefaultStageVariant({
  headline,
  image,
  imageMobile,
  imageFocalPoint,
  copy,
}: DefaultStageProps): ReactElement {
  const classes = useStyles()
  const { ref, inView } = useInView({
    triggerOnce: true,
  })

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'))

  return (
    <section className={classes.stageRoot}>
      {image && (
        <Image
          className={classes.stageImage}
          image={image.fluid}
          imageMobile={imageMobile?.fluid}
          focalPoint={imageFocalPoint || 'Upper right'}
          imgStyle={{ margin: '0' }}
          lazyLoadingOff={true}
        />
      )}
      {(headline || copy) && (
        <div
          ref={ref}
          className={clsx(classes.contentWrapper, {
            [classes.inView]: inView,
          })}
        >
          <div className={classes.contentInner} aria-label={headline}>
            {headline && (
              <Headline className={classes.headlineAnim} level={1}>
                {headline}
              </Headline>
            )}
            {copy && (
              <Copy
                className={clsx(classes.copy, classes.copyAnim)}
                richtext={copy}
                ariaRole="paragraph"
              />
            )}
          </div>
          <div className={classes.contentSiding} />
        </div>
      )}
    </section>
  )
}
