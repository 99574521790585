import React, { ReactElement } from 'react'
import RvG from '@/types/rvg'

import PressStageVariant, { PressStageProps } from './variants/press'
import DefaultStageVariant, { DefaultStageProps } from './variants/default'

type StagePropsUnion = DefaultStageProps | PressStageProps

export type StageProps = RvG.IReactDefaultProps & {
  type?: string
} & StagePropsUnion

export default function Stage({ type, ...props }: StageProps): ReactElement {
  switch (type) {
    case 'press':
      return <PressStageVariant {...props} />
    case 'default':
      return <DefaultStageVariant {...props} />
    default:
      return <DefaultStageVariant {...props} />
  }
}
