import React, { ReactElement } from 'react'
import { Grid, makeStyles } from '@material-ui/core'

import MetaTags from '@objects/metatags'
import Headline from '@objects/headline'
import Paragraph from '@objects/paragraph'
import Container from '@objects/container'
import BreadCrumbs from '@objects/breadcrumb'
import Stage from '@components/stage'

import TeaserTop from '../campaigns/bike-voting-intermot-winner/static/images/intermot.jpg'

const useStyles = makeStyles((theme) => ({
  link: {
    ...theme.mixins.linkStyleUnderlined,
    backgroundSize: '100% 2px',
    fontWeight: 700,
  },
  list: {
    padding: theme.spacing(0, 0, 0, 4),
    margin: 0,
    listStyle: 'none',
  },
  listItem: {
    '&::before': {
      content: '"\\2022"',
      color: theme.palette.primary.main,
      fontWeight: 500,
      display: 'inline-block',
      width: '1em',
      marginLeft: '-1em',
    },
  },
  stage: {
    '& img': {
      objectPosition: '50% 9% !important',
    },
  },
}))

export default function RootIndex(): ReactElement {
  const classes = useStyles()
  return (
    <>
      <MetaTags
        locale="de"
        meta={{
          metaTitle:
            'Teilnahmebedingungen der Foto-Aktion (Gewinnspiel) auf der INTERMOT in Köln',
          noIndex: true,
        }}
      />
      <BreadCrumbs
        crumbList={[
          {
            label: 'Motorrad-Fotowettbewerb',
            link: '/interaktion-und-videos/motorrad-fotowettbewerb',
          },
          {
            label: 'Teilnahmebedingungen',
          },
        ]}
      />
      <div className={classes.stage}>
        <Stage
          type="default"
          headline="Teilnahmebedingungen der Foto-Aktion (Gewinnspiel) auf der INTERMOT in Köln"
          image={{
            __typename: 'string',
            id: 'string',
            contentful_id: 'string',
            fluid: {
              aspectRatio: 1.2,
              src: TeaserTop,
              srcSet: '',
              sizes: '',
            },
          }}
        />
      </div>
      <Container>
        <Grid container justifyContent="center" spacing={8}>
          <Grid item xs={12} lg={10}>
            <Headline level={4}>1. Gewinnspiel</Headline>
            <Paragraph>
              Das Bundesministerium für Digitales und Verkehr (BMDV)
              veranstaltet im Rahmen der Verkehrssicherheitskampagne „Runter vom
              Gas“ ein Gewinnspiel am Stand der Biker Union e.V. auf der
              Branchenmesse INTERMOT 2022. Den Teilnehmenden wird die
              Möglichkeit gewährt, ein Foto von sich selbst mit einer Tafel
              aufnehmen zu lassen, auf dem ein selbst entworfener und
              geschriebener Aktionsspruch aufgebracht ist, der die Frage „Was
              bedeutet Verkehrssicherheit für dich?“ beantwortet. Ein
              öffentliches Voting kürt aus allen Fotos der Teilnehmenden die
              Gewinnerinnen und Gewinner. Die Teilnahme an diesem Gewinnspiel
              richtet sich nach den nachfolgenden Bedingungen.
            </Paragraph>

            <Headline level={4}>2. Veranstalter</Headline>
            <Paragraph>
              Veranstalter des Gewinnspiels ist das
              <br />
              <br />
              Bundesministerium für Digitales und Verkehr
              <br />
              Invalidenstraße 44
              <br />
              10115 Berlin
              <br />
              (nachfolgend „Veranstalter“ oder „BMDV“)
            </Paragraph>

            <Headline level={4}>
              3. Teilnehmer und Teilnahmevoraussetzungen
            </Headline>
            <Paragraph>
              Teilnahmeberechtigt sind alle uneingeschränkt rechtsfähigen
              natürlichen Personen in ihrem eigenen Namen, die mindestens 18
              Jahre alt sind und ihren Wohnsitz in Deutschland haben. Eine
              teilnehmende Person darf maximal einmal an dem Gewinnspiel
              teilnehmen. Gewinnspielclubs oder -agenten ist die Teilnahme an
              dem Gewinnspiel nicht gestattet.
            </Paragraph>
            <Paragraph>
              Die Teilnahme erfordert die wahrheitsgemäße Angabe der
              persönlichen Daten der Teilnehmenden.
            </Paragraph>
            <Paragraph>
              Folgende Pflichtangaben, die zur Durchführung des Gewinnspiels
              notwendig sind, müssen hierfür angegeben werden: Vor- und
              Nachname, E-Mail-Adresse und die gewünschte Helmgröße. Zur Auswahl
              stehen die Helmgrößen M, L und XXL.
            </Paragraph>

            <Headline level={4}>
              Veranstalterhinweis: Es sollte zu allen Zeiten ein passender und
              gutsitzender Helm genutzt werden, denn nur ein angepasster Helm
              schützt den/die Tragenden/Tragende.
            </Headline>
            <Paragraph>
              Um am Gewinnspiel teilnehmen zu können, ist außerdem die Aufnahme
              eines Fotos von der teilnehmenden Person zusammen mit dem
              Aktionsschild zum Thema Verkehrssicherheit erforderlich. Auf eine
              vom Veranstalter zur Verfügung gestellten Tafel schreibt die
              teilnehmende Person ihren Aktionsspruch zur Verkehrssicherheit.
              Mit Hilfe einer vom Veranstalter ebenfalls zur Verfügung
              gestellten Multimediasäule nimmt der Veranstalter ein Foto von der
              teilnehmenden Person zusammen mit der beschriebenen Tafel auf.
              Anschließend kann das Foto vor Ort ausgedruckt und auf Wunsch der
              teilnehmenden Person zusätzlich per E-Mail an diese versendet
              werden.
            </Paragraph>

            <Headline level={4}>4. Teilnahmeausschluss</Headline>
            <Paragraph>
              Der Veranstalter ist berechtigt, Teilnehmende vom Gewinnspiel
              auszuschließen, wenn sie diese Teilnahmebedingungen verletzen,
              falsche persönliche Daten angeben oder versuchen, dieses
              Gewinnspiel durch technische Manipulationen zu beeinflussen.
              Mitarbeitenden Personen der Initiatoren der Kampagne RUNTER VOM
              GAS und Personen, die mit der Durchführung des Gewinnspiels
              betraut sind, sowie deren Angehörige sind von der Teilnahme
              ausgeschlossen. Im Falle eines Ausschlusses vom Gewinnspiel, der
              auch nachträglich erfolgen kann, können die Gewinne nachträglich
              zurückgefordert werden.
            </Paragraph>

            <Headline level={4}>
              5. Durchführung des Gewinnspiels, Gewinnbenachrichtigung
            </Headline>
            <Paragraph>
              Die Gewinnerinnen und Gewinner werden durch ein öffentliches
              Voting bestimmt. Für das Voting werden von dem „Runter vom Gas“-
              Team des Veranstalters nach Teilnahmeschluss insgesamt 30 Fotos
              (jeweils 10 Fotos der jeweiligen Helmgrößen M, L und XXL) vorab
              ausgewählt. Diese 30 Fotos werden dann bis Anfang November für das
              Voting auf der Website des Veranstalters www.runtervomgas.de
              veröffentlicht. Nutzerinnen und Nutzer der Seite können in diesem
              Zeitraum für ihre favorisierten Aktionssprüche und Fotos
              abstimmen.
            </Paragraph>
            <Paragraph>
              Nach Abschluss des Votings werden die Gewinnerinnen und Gewinner
              im darauffolgenden Monat ermittelt. Da jeder Helm nur einmal in
              der jeweiligen Helmgröße M, L und XXL verfügbar ist, werden
              insgesamt drei Gewinnenden ermittelt, wobei die jeweils
              Bestplatzierten in der jeweiligen Kategorie der von ihnen
              angegeben Helmgröße den Helm in der entsprechenden Größe gewinnen.
            </Paragraph>
            <Paragraph>
              Die Gewinnerinnen und Gewinner werden persönlich per E-Mail
              benachrichtigt; darauf haben die Gewinnerinnen und Gewinner dem
              Veranstalter zwecks Versendung des Gewinns binnen 7 Tagen nach
              Zugang der Gewinnbenachrichtigung zu antworten und ihre
              postalische Anschrift mitzuteilen, damit die Gewinne per Post
              verschickt werden können. Wenn eine gewinnende Person ihre Adresse
              nicht fristgerecht mitteilt bzw. sie nicht erreicht werden kann
              oder der Gewinn als unzustellbar zurückgesandt wird, verfällt der
              Anspruch auf den Gewinn. Es wird dann die Person mit den
              zweitmeisten Stimmen in der entsprechenden Helmkategorie
              ermittelt.
            </Paragraph>

            <Headline level={4}>6. Gewinn</Headline>
            <Paragraph>Folgende Gewinne werden verlost:</Paragraph>
            <Paragraph>
              3 individuell gestaltete Motorradhelme der Marke „Bell“:
            </Paragraph>
            <Paragraph>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  Design 1: Flügel auf schwarzem Helm, Größe XXL (63–64 cm)
                </li>
                <li className={classes.listItem}>
                  Design 2: Heiligenschein auf schwarzem Helm, Größe L (59–60
                  cm)
                </li>
                <li className={classes.listItem}>
                  Design 3: Kreuz auf weißem Helm, Größe M (57–58 cm)
                </li>
              </ul>
            </Paragraph>

            <Headline level={4}>
              7. Vorzeitige Beendigung des Gewinnspiels
            </Headline>
            <Paragraph>
              Der Veranstalter behält sich vor, das Gewinnspiel zu jedem
              Zeitpunkt ohne Vorankündigung und ohne Angabe von Gründen
              abzubrechen oder zu beenden. Von dieser Möglichkeit macht der
              Veranstalter insbesondere dann Gebrauch, wenn aus technischen
              Gründen (z.B. Viren im Computersystem, Manipulation oder Fehler in
              der Hard- und/oder Software) oder aus rechtlichen Gründen eine
              ordnungsgemäße Durchführung des Gewinnspiels nicht gewährleistet
              werden kann.
            </Paragraph>
            <Paragraph>
              Wenn die Beendigung des Gewinnspiels aus einem dieser Gründe durch
              das Verhalten einer teilnehmenden Person verursacht wird, behält
              sich der Veranstalter das Recht vor, von dieser Person Ersatz des
              entstandenen Schadens zu verlangen.
            </Paragraph>

            <Headline level={4}>8. Fotorechte</Headline>
            <Paragraph bold>
              Die Teilnehmenden erklären sich damit einverstanden, dass ihr
              Vorname und die von ihnen im Rahmen des Gewinnspiels hergestellte
              Fotoaufnahme mit dem Aktionsspruch im Falle des Gewinns
              inhaltlich, zeitlich und räumlich uneingeschränkt von dem
              Veranstalter für PR-Zwecke in Rahmen der Kampagne „Runter vom Gas“
              auf der Website www.runtervomgas.de und auf den Social Media
              Seiten des Veranstalters (insbesondere Facebook und Instagram)
              veröffentlicht werden. Die Rechteeinräumung erfolgt unentgeltlich.
            </Paragraph>
            <Paragraph bold>
              Die Teilnehmenden können ihre Einwilligung jederzeit gegenüber dem
              Veranstalter widerrufen, und zwar telefonisch (030/700186-979),
              per Fax (030/700186-599) oder per E-Mail
              (kontakt@runtervomgas.de). Durch den Widerruf der Einwilligung
              wird die Rechtmäßigkeit der aufgrund der Einwilligung bis zum
              Widerruf erfolgten Verarbeitung der Fotoaufnahme nebst Nennung des
              Vor- und Nachnamens nicht berührt.
            </Paragraph>

            <Headline level={4}>9. Haftung des Veranstalters, Gewähr</Headline>
            <Paragraph>
              Der Veranstalter haftet nur für Vorsatz und grobe Fahrlässigkeit.
              Dies gilt nicht im Falle der fahrlässigen Verletzung einer
              wesentlichen Vertragspflicht. Dies sind Pflichten, die für die
              Gewinnspieldurchführung wesentlich sind, da sie diese erst
              ermöglichen, und auf deren Erfüllung der Teilnehmer deshalb
              vertraut und vertrauen darf. In diesem Fall ist die Haftung des
              Veranstalters bei einfacher Fahrlässigkeit auf den
              gewinnspieltypischen und vorhersehbaren Schaden beschränkt. Für
              Schäden aus der Verletzung des Lebens, Körpers oder der Gesundheit
              gilt die Haftungsbeschränkung nicht. Der Veranstalter haftet nicht
              für Schäden aus der Beeinträchtigung der Verfügbarkeit seiner
              Internetseiten bei nicht beeinflussbaren technischen Störungen und
              Ereignissen höherer Gewalt sowie Angriffen Dritter gegenüber der
              Internetseiten.
            </Paragraph>
            <Paragraph>
              Der Veranstalter leistet keine Gewähr für die Mangelfreiheit der
              Gewinne.
            </Paragraph>

            <Headline level={4}>10. Datenschutz</Headline>
            <Paragraph>
              Die vorliegenden Datenschutzhinweise gelten für die Verarbeitung
              von personenbezogenen Daten im Rahmen der Durchführung des
              Gewinnspiels.
            </Paragraph>

            <Headline level={4}>a. Verantwortliche</Headline>
            <Paragraph>
              Verantwortlicher im datenschutzrechtlichen Sinne ist:
              <br />
              Bundesministerium für Digitales und Verkehr
              <br />
              Invalidenstraße 44, 10115 Berlin
              <br />
              E-Mail: poststelle@bmdv.bund.de, Telefon: +49 30 18 300-0, Fax:
              +49 30 18 300 1920
            </Paragraph>

            <Headline level={4}>b. Datenschutzbeauftragter</Headline>
            <Paragraph>
              Beauftragter für den Datenschutz im BMDV
              <br />
              Bundesministerium für Digitales und Verkehr
              <br />
              Robert-Schuman-Platz 1, 53175 Bonn
              <br />
              E-Mail: datenschutz@bmdv.bund.de, Telefon: 0228 99-300-0
            </Paragraph>

            <Headline level={4}>
              c. Umfang und Zweck der Datenverarbeitung
            </Headline>
            <Paragraph>
              Bei einer Teilnahme am Gewinnspiel verarbeiten wir von Ihnen die
              nachfolgenden personenbezogenen Daten:
            </Paragraph>
            <Paragraph>
              <ul className={classes.list}>
                <li className={classes.listItem}>
                  Foto, E-Mail-Adresse, Vorname, Helmgröße.
                </li>
              </ul>
            </Paragraph>
            <Paragraph>
              Die Datenverarbeitung dient ausschließlich dem Zweck der
              Durchführung des Gewinnspiels und im Falle eines Gewinns zu PR-
              Zwecken im Rahmen der Kampagne „Runter vom Gas“.
            </Paragraph>
            <Paragraph>
              Ihren vollständigen Namen und Ihre postalische Anschrift, die wir
              im Falle einer Gewinnbenachrichtigung von Ihnen per E-Mail
              anfragen, dient ausschließlich der Versendung des Gewinns.
            </Paragraph>

            <Headline level={4}>d. Rechtsgrundlage der Verarbeitung</Headline>
            <Paragraph>
              Die Rechtsgrundlage für die Verarbeitung personenbezogener Daten
              zur Erfüllung dieses Schuldverhältnisses ist Art. 6 Abs. 1 lit. b)
              der EU-Datenschutzgrundverordnung (DSGVO). Soweit Sie ausdrücklich
              Ihre Einwilligung zur Verwendung der Daten erteilen, ist
              Rechtsgrundlage für die Verarbeitung personenbezogener Daten Art.
              6 Abs. 1 lit. a) der EU-Datenschutzgrundverordnung (DSGVO).
            </Paragraph>

            <Headline level={4}>Empfänger der personenbezogenen Daten</Headline>
            <Paragraph>
              Ihre Daten werden grundsätzlich nicht an Dritte weitergegeben, es
              sei denn, die Weitergabe ist für die Durchführung des Gewinnspiels
              notwendig und es liegt eine datenschutzrechtliche
              Übermittlungsbefugnis vor.
            </Paragraph>
            <Paragraph>
              Im Übrigen setzt das BMDV weitere Dienstleister
              (Auftragsverarbeiter) für die Verarbeitung personenbezogener Daten
              ein, unter anderem für die Versendung von E-Mails, die Speicherung
              Ihrer Daten in einem Rechenzentrum sowie die Pflege und Analyse
              von Datenbanken.
            </Paragraph>
            <Paragraph>
              Sämtliche Auftragsverarbeitende wurden sorgfältig ausgewählt,
              unterstützen das BMDV streng weisungsgebunden und erhalten nur in
              dem Umfang und für den benötigten Zeitraum Zugang zu Ihren Daten,
              der für die Erbringung der Leistungen erforderlich ist bzw. in dem
              Umfang, in dem Sie in die Datenverarbeitung eingewilligt haben.
            </Paragraph>

            <Headline level={4}>e. Speicherdauer</Headline>
            <Paragraph>
              Nach Ablauf des Gewinnspiels werden Ihre im Rahmen des
              Gewinnspiels verarbeiteten Daten innerhalb von 14 Tagen gelöscht.
            </Paragraph>
            <Paragraph>
              Die Fotos der Gewinnerinnen und Gewinner werden so lange
              gespeichert, wie der Veranstalter davon Gebrauch machen möchte,
              maximal jedoch bis zu einem Widerruf der Einwilligungserklärung.
            </Paragraph>

            <Headline level={4}>f. Ihre Betroffenenrechte</Headline>
            <Paragraph>
              Soweit das BMDV personenbezogene Daten von Ihnen verarbeitet,
              haben Sie das Recht auf Auskunft über die Sie betreffenden
              personenbezogenen Daten (Art. 15 DSGVO), auf Berichtigung (Art.16
              DSGVO), Löschung (Art. 17 DSGVO) oder Einschränkung der
              Verarbeitung (Art. 18 DSGVO) sowie das Recht, der Verarbeitung
              aufgrund einer besonderen Situation zu widersprechen (Art. 21
              DSGVO).
            </Paragraph>
            <Paragraph>
              Wenn Sie annehmen, dass die Verarbeitung Ihrer persönlichen Daten
              Ihre Rechte verletzt, können Sie sich an die zuständige
              Datenschutzaufsichtsbehörde wenden (Art. 77 DSGVO): Der
              Bundesbeauftragte für den Datenschutz und die Informationsfreiheit
              (BfDI), Graurheindorfer Straße 153, 53117 Bonn, E-Mail:
              poststelle@bfdi.bund.de.
            </Paragraph>

            <Headline level={4}>11. Rechtsweg</Headline>
            <Paragraph>
              Der Rechtsweg ist im Hinblick auf die Ermittlung der Gewinnerinnen
              und Gewinner und die Beurteilung der eingereichten
              Gewinnspielbeiträge ausgeschlossen.
            </Paragraph>
            <Paragraph>Köln, September 2022</Paragraph>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}
